import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons";

const Quote = (prop: { text: string; author: string }) => {
  return (
    <div className="container quote">
      <div className="row">
        <div className="col">
          <p className="leftquote">
            <FontAwesomeIcon icon={faQuoteLeft} size="2x" />
          </p>
        </div>
        <div className="col-6 text">
          <p className="title">{prop.text}</p>
          <p className="author">{prop.author}</p>
        </div>
        <div className="col">
          <p className="rightquote">
            <FontAwesomeIcon icon={faQuoteRight} size="2x" />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Quote;
