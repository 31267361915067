import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

const PageHeader = (prop: { title: string; subtitle: string; icon: IconDefinition }) => {
  return (
    <div className="d-flex flex-row align-items-center app-page-title">
      <div className="p-2">
        <div className="app-page-title-icon-container rounded">
          <FontAwesomeIcon icon={prop.icon} size="3x" />
        </div>
      </div>
      <div className="p-2">
        <div className="app-page-title-text">{prop.title}</div>
        <div className="app-page-title-subtext">{prop.subtitle}</div>
      </div>
    </div>
  );
};

export default PageHeader;
