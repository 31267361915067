import Breadcrumb from "../components/Breadcrumb";
import PageHeader from "../components/PageHeader";
import Quote from "../components/Quote";
import { faIdCard } from "@fortawesome/free-solid-svg-icons";

const AboutPage = () => {
  return (
    <div>
      <Breadcrumb names={["About Us"]} />
      <div className="container">
        <PageHeader icon={faIdCard} title="About Us" subtitle="On peut rire de tout mais pas avec n’importe qui." />
        <Quote text="Even I can't talk about myself for an hour." author="Ricky Gervais" />
        <div className="row">
          <div className="col-12">
            <p>
              I'm an IT professional with over 20 years of experience, 10 of those in leadership roles. I started my career as a C programmer for
              Windows, and as I advanced in the profession, my technology skills grew to include
            </p>
            <ul>
              <li>
                <strong>OS</strong>: Windows, Linux, Aix, OS/2, OS/390.
              </li>
              <li>
                <strong>Programming Languages</strong>: C, C++, Assembly x86, Java, Perl, Php, Python, JavaScript, Smalltalk, etc...
              </li>
              <li>
                <strong>Industries</strong>: Banks, Insurance companies, Telecomunication, Data-center, SaaS.
              </li>
            </ul>
            <h3>A few things that I eventually realized</h3>
            <ul>
              <li>
                Platform and programming languages are implementation details. If you are a .NET programmer, you are not going to melt if you touch
                some Javascript code.
              </li>
              <li>Technology is easy and predictable; people are much more random by nature.</li>
              <li>
                One of my main motivations is to help people, although I do not consider myself to be a people person. Nice contradiction right there
                :)
              </li>
            </ul>
            <p>As an Engineer, I always focused on delivering reliable solutions that can evolve.</p>
            <p>As I grew into leadership roles, I focused on bringing the best out of my team while providing the best service for my customers.</p>

            <h3>Challenge Mode: Technical Support!</h3>
            <p>
              During my career, I was always looking for challenging roles. In my experience, leading a Technical Support / Success organization is
              probably one of the most challenging positions because of the following:
            </p>
            <ul>
              <li>
                <strong>Support needs to know everything.</strong> A developer will specialize in a few components of the application; an Operations
                engineer will usually specialize in a certain area as well (deployment, server administration, database optimization); and a PM will
                usually control a handful of features. On the other hand, even the most junior Tier-1 support engineer must be able to answer
                questions about all of the features of the company's offerings and be able to assist customers with complex troubleshooting. Training
                is the key to success and encourages constant learning in many areas.
              </li>
              <li>
                <strong>Support has very little control over its workload.</strong>
                Support is very dependent on the performance of other departments within the company and will be in the front line when something bad
                happens. Communication with every department is key to working efficiently.
              </li>
              <li>
                <strong>Support has a bad reputation.</strong> Let's be honest, all of us have had horrible experiences dealing with Support teams at
                some point, and this is a stigma most Support Engineers carry with them in their day-to-day life. Fighting to establish a good
                reputation for the Support Team of a company is an exciting,rewarding (and sometimes, frustrating!) challenge.
              </li>
            </ul>
            <p>
              Technical Support needs to have a holistic view and the ability to troubleshoot every type of issue. If you are Supporting a SaaS
              product, Support engineers, even Tier-1, need to be able to troubleshoot front-end problems, back-end issues, vendor API calls,
              networking problems, etc. If done right, customers opening an issue should always feel that they are speaking with an expert.
            </p>
            <p>I found this challenge appealing, because it forced me to keep my mind sharp and stay current with all technologies.</p>
            <h3>Why Codewriter?</h3>
            <p>
              Many years ago, a co-worker would stop by my desk and to grab my attention, he would dance and sing his lyrics to the melody from
              "Lowrider" by War, -- he switched "Lowrider" to "Codewriter". While a very confusing, bit scary, and slightly creepy way of getting my
              attention, it was also hilarious.
            </p>
            <p>I decided to play along with the joke, bought the domain codewriter.net and changed my car's license plate. It stuck to this day!</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
