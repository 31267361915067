import { ApiUrl } from "./ApiUrl";

export interface Host {
  id: number;
  name: string;
  machine_id: string;
  ip_address: string;
  last_update: string;
}

export const fetchHost = async (id: number): Promise<Host | undefined> => {
  try {
    const response = await fetch(`${ApiUrl}/hosts/${id}`);
    if (response.ok) {
      return response.json();
    }
  } catch (error) {}
};
