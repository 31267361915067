import Breadcrumb from "../components/Breadcrumb";
import PageHeader from "../components/PageHeader";
import Quote from "../components/Quote";
import { faLinux } from "@fortawesome/free-brands-svg-icons";

const ITOperationsPage = () => {
  return (
    <div>
      <Breadcrumb names={["Services", "IT Operations"]} />
      <div className="container">
        <PageHeader icon={faLinux} title="IT Operations" subtitle="Repeatedly fix what you repeatedly break." />
        <Quote text="If all you have is a hammer, everything looks like a nail." author="Abraham H. Maslow (1962) Toward a Psychology of Being" />
        <div className="row">
          <div className="col-12">
            <p>
              Building and managing the Operations Team for a Data Center company was an interesting challenge. Starting with two cheap Compaq
              computers purchased at BestBuy to host a few ISP services, I helped the company evolve into a very complex infrastructure spread across
              seven Data Centers
            </p>
            <h3>Tools are not substitutes for strategies.</h3>
            <p>
              Keeping everything running while constantly growing requires dedication and organization. Tools and technologies come and go, but
              overall strategies remain. I believe that to be successful, Operations must rely on having good high-level strategies around a few key
              components: Monitoring, Deployment, and Disaster recovery.
            </p>
            <h3>See everything!</h3>
            <p>
              The Operations Team should always be the first to know about anything that happens in the environment. A good monitoring strategy
              combined with meaningful notifications is key to providing a positive customer experience. Polishing your monitoring and notification
              systems is almost an art. My overall goals for good monitoring include:
            </p>
            <ul>
              <li>Easy to read dashboards.</li>
              <li>User experience monitoring, not only physical or logical infrastructure.</li>
              <li>Meaningful and actionable notifications.</li>
            </ul>
            <h3>Control everything!</h3>
            <p>
              A successful Operations Team is all about control, procedures, and policies. Every action that is performed needs to be controlled,
              reviewed, and tested to prevent accidents. This includes:
            </p>
            <ul>
              <li>Strict access control policies.</li>
              <li>Clear and enforced deployment procedures.</li>
              <li>Effective configuration management.</li>
            </ul>
            <h3>A healthy dose of paranoia.</h3>
            <p>
              As Murphy's Law clearly states: "Anything that can go wrong will go wrong." The Operations Team needs to be prepared for every scenario
              such as:
            </p>
            <ul>
              <li>Identifying single points of failures, including vendors of SaaS, PaaS, or IaaS solution.</li>
              <li>Performing backups and testing the restoration procedure.</li>
              <li>Designing complete Disaster Recovery plans.</li>
            </ul>
            <h3>Keep technical debt low.</h3>
            <p>
              While major outages are scary, many Operations Teams tend to waste most of their time dealing with small repeat incidents. While it's
              tempting to put and-aids on those little issues, they tend to come back regularly and waste time over and over again. My experience
              shows that it's cost-effective to completely fix small issues as they arise to keep the technical debt low and avoid regular
              interruptions.
            </p>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ITOperationsPage;
