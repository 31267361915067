import Breadcrumb from "../components/Breadcrumb";
import PageHeader from "../components/PageHeader";
import Quote from "../components/Quote";
import { faLifeRing } from "@fortawesome/free-regular-svg-icons";

const CustomerSuccessPage = () => {
  return (
    <div>
      <Breadcrumb names={["Services", "Customer Success"]} />
      <div className="container">
        <PageHeader icon={faLifeRing} title="Customer Support/Success" subtitle="A satisfied customer. We should have him stuffed." />
        <Quote
          text="You can focus on adoption, retention, expansion, or advocacy; or you can focus on the customers'
                    Desired Outcome and get all of those things."
          author="Lincoln Murphy"
        />

        <div className="row">
          <div className="col-12">
            <p>
              While Customer Support and Customer Success are two different organizations with very different modes of operation, strategies, and
              metrics, their mission is the same: "Ensuring that customers are satisfied and successful." In both cases, the focus needs to be on the
              customer's desired outcome. There are a few strategies that I believe are key to be successful.
            </p>

            <h3>Instilling a “customer-first” mindset across the enterprise.</h3>
            <p>
              One of my priorities is training colleagues across departments to step into the shoes of the customer and understand their needs, rather
              than viewing them as “tickets,” “issues,” or “complaints.” Creating this customer-centric culture results in exceptional customer
              satisfaction and high levels of customer retention—thereby ensuring consistent revenue and business growth.
            </p>

            <h3>Using metrics to drive alignment and accountability.</h3>
            <p>
              It is important to design meaningful KPIs to link organization vision to individual action by quantifying goals and evaluating
              performance accordingly. The value of being aligned and accountable is that it creates clarity and focus across people and teams which
              fosters employee motivation and drive.
            </p>

            <h3>Letting customers teach you!</h3>
            <p>
              Communication is a two-way street, and we often forget to stop and truly listen to the customer. Reading books, watching videos, and
              attending conferences can be great sources of inspiration and information, but it’s important to remind ourselves to learn from and be
              inspired by the customer…every day. They are the experts!
            </p>

            <h3>Improving efficiency by using technology to organize data and automate enterprise operations.</h3>
            <p>
              It is important to never lose sight of the long-term benefits of establishing meaningful customer relationships with the “human touch.”
              However, implementing automation tools thoughtfully can save time for the engineers as well as the customers by quickly identifying
              issues, troubleshooting tickets, and creating customized response templates.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerSuccessPage;
