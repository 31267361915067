import "./App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import ScrollToTop from "./components/ScrollToTop";
import Headers from "./components/Headers";
import Footers from "./components/Footers";

import AboutPage from "./pages/AboutPage";
import HomePage from "./pages/HomePage";
import SoftwareEngineeringPage from "./pages/SoftwareEngineeringPage";
import CustomerSuccessPage from "./pages/CustomerSuccessPage";
import ITOperationsPage from "./pages/ITOperationsPage";
import MinecraftPage from "./pages/MinecraftPage";

function App() {
  return (
    <div id="wrapper">
      <BrowserRouter>
        <ScrollToTop />
        <Headers />
        <Routes>
          <Route path="/about" element={<AboutPage />} />
          <Route path="/softwareenginnering" element={<SoftwareEngineeringPage />} />
          <Route path="/itoperations" element={<ITOperationsPage />} />
          <Route path="/customersuccess" element={<CustomerSuccessPage />} />
          <Route path="/minecraft" element={<MinecraftPage />} />
          <Route path="/" element={<HomePage />} />
        </Routes>
        <Footers />
      </BrowserRouter>
    </div>
  );
}

export default App;
