import "./MinecraftInstance.scss";
import MinecraftInstance from "./MinecraftInstance";

import { HostInstance } from "../models/HostInstance";

const MinecraftInstances = (prop: { instances: HostInstance[] }) => {
  return (
    <div className="container">
      <div className="row">
        {prop.instances.map((instance) => (
          <MinecraftInstance key={instance.id} instance={instance} />
        ))}
      </div>
    </div>
  );
};

export default MinecraftInstances;
