import "./Footers.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";

const Footers = () => {
  return (
    <div className="container">
      <footer id="footer">
        <div className="container">
          <div className="row no-margin-bottom">
            <div className="col-lg-4 col-md-6 footer-info">
              <h4>CodeWriter</h4>
              <p>Delivering a superior experience that drives customer satisfaction.</p>
            </div>

            <div className="col-lg-4 col-md-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li className="py-1">
                  <Link to="/">Home</Link>
                </li>
                <li className="py-1">
                  <Link to="/softwareenginnering">Software Enginnering</Link>
                </li>
                <li className="py-1">
                  <Link to="/itoperations">IT Operations</Link>
                </li>
                <li className="py-1">
                  <Link to="/customersuccess">Customer Success</Link>
                </li>
                <li className="py-1">
                  <Link to="/about">About Us</Link>
                </li>
              </ul>
            </div>

            <div className="col-lg-4 col-md-6 footer-contact">
              <h4>Contact Us</h4>
              <p>
                <strong>Email:</strong> fabrice@codewriter.net
                <br />
              </p>
              <div className="social-links">
                <a href="https://www.linkedin.com/in/fabriceling/" aria-label="LinkedIn">
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
                <a href="https://github.com/FabriceLing" aria-label="GitHub">
                  <FontAwesomeIcon icon={faGithub} />
                </a>
              </div>
            </div>
          </div>
        </div>
       </footer>
     </div>
  );
};

export default Footers;
