import "./MinecraftInstance.scss";
import { HostInstance, isOnline, formatDateInterval } from "../models/HostInstance";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const MinecraftInstance = (prop: { instance: HostInstance }) => {

  return (
    <div className="col-4">
      <div className="card">
        <img src={"assets/images/" + prop.instance.image + ".png"} className="card-img-top" alt="..." />
        <div className="card-body">
          <h5 className="card-title">{prop.instance.version}</h5>
          <p className="card-text">{prop.instance.name}</p>
          <p className="card-text">
            {isOnline(prop.instance) ? (
              <FontAwesomeIcon data-testid='icon' icon={faCheck} className="fa-green" title={formatDateInterval(prop.instance)} />
            ) : (
              <FontAwesomeIcon data-testid='icon' icon={faTimes} className="fa-red" title={formatDateInterval(prop.instance)} />
            )}
            &nbsp;{prop.instance.ip_address}:{prop.instance.port}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MinecraftInstance;
