import { fetchInstances } from "./Instance";
import { fetchHost } from "./Host";

export interface HostInstance {
  id: number;
  host_id: number;
  name: string;
  version: string;
  ip_address: string;
  port: string;
  image: string;
  last_update: string;
}

export const getInstances = async (): Promise<HostInstance[] | undefined> => {
  let newInstances: HostInstance[] = [];
  let hosts = new Map();

  // Get the list of instances.
  const instances = await fetchInstances();

  // If we got a response.
  if (instances != null) {
    // Retrieve the list of unique host_id.
    let host_ids = new Set<number>(instances.map((obj: any) => obj.host_id));

    // Fetch each of those hosts.
    for (const host_id of Array.from(host_ids)) {
      const host = await fetchHost(host_id);
      if (host != null) {
        hosts.set(host_id, host);
      }
    }

    // Interate through the list of responses.
    for (const instance of instances) {
      if (hosts.has(instance.host_id)) {
        let i: HostInstance = {
          id: instance.id,
          host_id: instance.id,
          name: instance.name,
          version: instance.version,
          ip_address: hosts.get(instance.host_id).ip_address,
          port: instance.port,
          image: instance.image,
          last_update: hosts.get(instance.host_id).last_update,
        };
        newInstances.push(i);
      }
    }
    return newInstances;
  }
};

export function isOnline(instance: HostInstance) {
  const seconds = Math.floor((+new Date(Date.now()) - +new Date(instance.last_update)) / 1000);

  if (seconds < 60 * 5) {
    return true;
  }
  return false;
}

export function pluralizeUnit(count: number, unit: string): string {
  if (count === 1) {
    return unit;
  }
  return unit + "s";
}

export function formatDateInterval(instance: HostInstance) {
  const seconds = Math.floor((+new Date(Date.now()) - +new Date(instance.last_update)) / 1000);

  // If less than 30 seconds ago.
  if (seconds < 30) {
    return "Just now";
  }

  const SECONDS = 1;
  const MINUTES = SECONDS * 60;
  const HOURS = MINUTES * 60;
  const DAYS = HOURS * 24;

  if (seconds < MINUTES) {
    return seconds + " " + pluralizeUnit(seconds, "second") + " ago";
  }
  if (seconds < HOURS) {
    let counter = Math.floor(seconds / MINUTES);
    return counter + " " + pluralizeUnit(counter, "minute") + " ago";
  }
  if (seconds < DAYS) {
    let counter = Math.floor(seconds / HOURS);
    return counter + " " + pluralizeUnit(counter, "hour") + " ago";
  }

  let counter = Math.floor(seconds / DAYS);
  return counter + " " + pluralizeUnit(counter, "day") + " ago";
}
