import Breadcrumb from "../components/Breadcrumb";
import PageHeader from "../components/PageHeader";
import Quote from "../components/Quote";
import { faCode } from "@fortawesome/free-solid-svg-icons";

const SoftwareEngineeringPage = () => {
  return (
    <div>
      <Breadcrumb names={["Services", "Software Engineering"]} />
      <div className="container">
        <PageHeader icon={faCode} title="Software Engineering" subtitle="It's not a bug - it's an undocumented feature." />
        <Quote
          text="If debugging is the process of removing software bugs, then programming must be the process of putting them in."
          author="Edsger W. Dijkstra"
        />
        <div className="row">
          <div className="col-12">
            <p>
              Writing software has been my hobby and passion since I was a little boy. I learned to program in BASIC in the early 80s which sparked my
              curiosity to learn everything about computers. I found it fascinating how quickly technology evolved and new languages appeared, allowing
              the creation of more complex applications.
            </p>
            <p>
              But in reality, the role of a software engineering team is to deliver a practical solution to the end-users. Users do not care how
              things work, which technology is being used, or how beautiful an algorithm or architecture is behind the interface. They do care if the
              solution is robust and provides the functionality they need. The more complex the problem is, the easier the solution needs to be for
              the end-user.
            </p>

            <h3>Self-inflicted wounds.</h3>
            <p>
              It is interesting that over the years, we have introduced new tools and best practices to speed up development and improve the stability
              of applications, but generally, projects now require more engineers, take longer to complete, and are usually less stable because of the
              complex interaction of the libraries and framework used.
            </p>
            <h3>Keep up!</h3>
            <p>
              As a leader or an engineer, the biggest challenge for software engineeringis to keep up with the constant changes and evolution. New
              platforms, frameworks, and designs are released all of the time, so constantly staying up-to-date requires lots of time and energy
              devoted to learning. . We have to be very careful not to fall into the "hype trap".
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SoftwareEngineeringPage;
