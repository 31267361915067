import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

const Breadcrumb = (prop: { names: string[] }) => {
  return (
    <section id="breadcrumb">
      <div className="container">
        <div className="row">
          <div className="span8">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  <FontAwesomeIcon icon={faHome} />
                </Link>
              </li>
              {prop.names.map((name,i) => (
                <li key={i} className="breadcrumb-item">{name}</li>
              ))}
            </ol>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Breadcrumb;
