import { ApiUrl } from "./ApiUrl";

export interface Instance {
  id: number;
  host_id: number;
  port: string;
  name: string;
  version: string;
  image: string;
}

export const fetchInstances = async (): Promise<Instance[] | undefined> => {
  try {
    const response = await fetch(`${ApiUrl}/instances`);
    if (response.ok) {
      return response.json();
    }
  } catch (error) {}
};
