import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const Headers = () => {
  return (
    <Navbar bg="primary" variant="dark" expand="lg">
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>CodeWriter Networks</Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <LinkContainer to="/">
              <Nav.Link>Home</Nav.Link>
            </LinkContainer>
            <NavDropdown title="Services" id="basic-nav-dropdown">
              <LinkContainer to="/softwareenginnering">
                <NavDropdown.Item>Software Enginnering</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/itoperations">
                <NavDropdown.Item>IT Operations</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/customersuccess">
                <NavDropdown.Item>Customer Success</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
            <LinkContainer to="/about">
              <Nav.Link>About Us</Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Headers;
