import HomePageCarousel from "../components/HomePageCarousel";
import HomePageCards from "../components/HomePageCards";

const HomePage = () => {
  return (
    <div className="App-page">
      <HomePageCarousel />
      <HomePageCards />
    </div>
  );
};

export default HomePage;
