import { useState, useEffect } from "react";

import { HostInstance, getInstances } from "../models/HostInstance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faCubes } from "@fortawesome/free-solid-svg-icons";

import Breadcrumb from "../components/Breadcrumb";
import PageHeader from "../components/PageHeader";
import MinecraftInstances from "../components/MinecraftInstances";

const MinecraftPage = () => {
  const [instances, setInstances] = useState<HostInstance[]>([]);

  const loadInstances = async () => {
    // Get the list of instances.
    const instancesFromServer = await getInstances();
    if (instancesFromServer != null) {
      setInstances(instancesFromServer);
    }
  };

  useEffect(() => {
    // Retrieve the list of instances.
    loadInstances();
    // Call getInstances on a timer.
    var handle = setInterval(loadInstances, 5000);

    return () => {
      clearInterval(handle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Breadcrumb names={["Minecraft"]} />
      <div className="container">
        <PageHeader icon={faCubes} title="Minecraft Servers" subtitle="Don't Fear The Creeper." />
        {instances.length > 0 ? (
          <MinecraftInstances instances={instances} />
        ) : (
          <div className="container">
            <div className="row">
              <p className="text-center">
                <FontAwesomeIcon icon={faSpinner} className="fa-spin" /> Loading...
              </p>
            </div>
          </div>
        )}
        <div className="container mt-3">
          <div className="row">
            <div className="col-12">
              <h3>Frequently Asked Questions</h3>
              <p className="question">Is the server down?</p>
              <p className="answer">The indicator should tell if if the server is currently active or not.</p>
              <p className="question">Why is the server down?</p>
              <p className="answer">I'm either performing maintenance on the server, or my ISP went down.</p>
              <p className="question">How long until it comes back online?</p>
              <p className="answer">It will be back soon(tm).</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MinecraftPage;
