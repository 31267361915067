import "./HomePageCarousel.scss";

import Carousel from "react-bootstrap/Carousel";

const HomePageCarousel = () => {
  return (
    <div className="container" id="carousel">
      <Carousel>
        <Carousel.Item interval={1000}>
          <img className="d-block w-100" src="/assets/images/carousel/source-code.png" alt="Software Engineering" />
          <Carousel.Caption>
            <h4>Software Engineering</h4>
            <p>It's not a bug - it's an undocumented feature.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={1000}>
          <img className="d-block w-100" src="/assets/images/carousel/servers.png" alt="IT Operation" />
          <Carousel.Caption>
            <h4>IT Operation</h4>
            <p>Repeatedly fix what you repeatedly break.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={1000}>
          <img className="d-block w-100" src="/assets/images/carousel/customer.png" alt="Customer Support/Success" />
          <Carousel.Caption>
            <h4>Customer Support/Success</h4>
            <p>A satisfied customer. We should have him stuffed.</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default HomePageCarousel;
