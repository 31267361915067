import "./HomePageCards.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { faLinux } from "@fortawesome/free-brands-svg-icons";
import { faLifeRing } from "@fortawesome/free-regular-svg-icons";

const HomePageCards = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <div className="card border-primary h-100">
            <div className="card-body">
              <h5 className="text-center">
                <span className="fa-stack fa-3x">
                    <FontAwesomeIcon className="fa-stack fa-stack-2x fa-blue" icon={faCircle}/>
                    <FontAwesomeIcon className="fa-stack fa-stack-1x" icon={faCode}/>
                </span>
              </h5>
              <h5 className="card-title text-center">Software Engineering</h5>
              <p className="card-text">25 years of experience of converting caffeine into functioning software.</p>
              <Link className="link stretched-link" to="/softwareenginnering">Read more...</Link>
            </div>
          </div>
        </div>

        <div className="col">
          <div className="card border-primary h-100">
            <div className="card-body">
              <h5 className="text-center">
              <span className="fa-stack fa-3x">
                    <FontAwesomeIcon className="fa-stack fa-stack-2x fa-blue" icon={faCircle}/>
                    <FontAwesomeIcon className="fa-stack fa-stack-1x" icon={faLinux}/>
                </span>
              </h5>
              <h5 className="card-title text-center">IT Operations</h5>
              <p className="card-text">When turning it off and on again isn't enough.</p>
              <Link className="link stretched-link" to="/itoperations">Read more...</Link>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card border-primary h-100">
            <div className="card-body">
              <h5 className="text-center">
              <span className="fa-stack fa-3x">
                    <FontAwesomeIcon className="fa-stack fa-stack-2x fa-blue" icon={faCircle}/>
                    <FontAwesomeIcon className="fa-stack fa-stack-1x" icon={faLifeRing}/>
                </span>
              </h5>
              <h5 className="card-title text-center">Customer Success</h5>
              <p className="card-text">Customer service is not a department, it's everyone's job.</p>
              <Link className="link stretched-link" to="/customersuccess">Read more...</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageCards;

